import { Circle } from "react-konva";

export const Indicator = (props) => {
  const { x, y } = props;
  return (
    <>
      <Circle x={x} y={y} radius={10} fill="orange" />
      <Circle x={x} y={y} radius={5} fill="white" />
    </>
  );
};
