import "./App.css";
import { ShapeWithControls } from "./components/ShapeWithControls";
import { useEffect, useState } from "react";
import useSound from "use-sound";
import bongo from "./sounds/bongo.mp3";
import cymbal from "./sounds/cymbal.mp3";
import snare from "./sounds/snare.mp3";
import { Button } from "./components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";
import { Slider } from "./components/ui/slider";
import { Label } from "./components/ui/label";

function App() {
  const [volume, setVolume] = useState(0.3);
  const soundOptions = { volume: volume };
  const [playBongo] = useSound(bongo, soundOptions);
  const [playCymbal] = useSound(cymbal, soundOptions);
  const [playSnare] = useSound(snare, soundOptions);
  const sounds = {
    bongo: playBongo,
    cymbal: playCymbal,
    snare: playSnare,
  };
  const [thetaStep, setThetaStep] = useState(2);
  const [theta, setTheta] = useState(0);
  const [shapeDataList, setShapeDataList] = useState([]);
  useEffect(() => {
    const interval = setInterval(() => {
      const angle = (theta + thetaStep) % 360;
      setTheta(angle);
    }, 20);
    return () => clearInterval(interval);
  }, [theta, thetaStep]);
  const addShape = (event) => {
    setShapeDataList(
      shapeDataList.concat([
        { key: shapeDataList.length, sound: "bongo", vertexCount: 3 },
      ])
    );
  };
  const updateShape = (key, data) => {
    const clonedData = JSON.parse(JSON.stringify(shapeDataList));
    console.log(shapeDataList);
    const updatedKey = {
      ...clonedData[key],
      ...data,
    };
    console.log(updatedKey);
    clonedData[key] = updatedKey;
    console.log(clonedData);
    setShapeDataList(clonedData);
  };
  const updateVolume = (event) => {
    const newVolume = event[0];
    setVolume(newVolume);
  };
  const updateSpeed = (event) => {
    const newStep = event[0];
    setThetaStep(newStep);
  };
  return (
    <>
      <div className={"md:container"}>
        <h1 className="mx-auto p-5 w-full text-center scroll-m-20 text-4xl font-bold tracking-tight lg:text-5xl">
          Polyrhythm Visualizer
        </h1>
        <p className={"leading-7 md:w-6/12 mx-auto text-center pb-5"}>
          Visualize complex rhythms with this Polyrhythm Visualizer, an
          interactive tool that illustrates how different beats align and
          interact.
        </p>
        {shapeDataList.map((shapeData) => {
          return (
            <ShapeWithControls
              key={shapeData["key"]}
              index={shapeData["key"]}
              update={updateShape}
              theta={theta}
              thetaStep={thetaStep}
              sound={sounds[shapeData["sound"]]}
              vertexCount={shapeData["vertexCount"]}
            />
          );
        })}
        <Card className={"m-2.5 md:w-3/12 md:mx-auto"}>
          <CardHeader>
            <CardTitle>Controls</CardTitle>
          </CardHeader>
          <CardContent>
            <Button onClick={addShape}>Add Rhythm</Button>
            <div
              className="grid w-full max-w-sm items-center gap-1.5"
              style={{ marginTop: "5px" }}
            >
              <Label htmlFor="volume">Volume</Label>
              <Slider
                onValueChange={updateVolume}
                id="volume"
                defaultValue={[0.4]}
                max={1}
                step={0.05}
              />
            </div>
            <div
              className="grid w-full max-w-sm items-center gap-1.5"
              style={{ marginTop: "5px" }}
            >
              <Label for="volume">Speed</Label>
              <Slider
                onValueChange={updateSpeed}
                id="volume"
                defaultValue={[2]}
                min={1}
                max={10}
                step={1}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default App;
