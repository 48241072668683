import { Polygon } from "./Polygon";
import { Stage, Layer } from "react-konva";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

export const ShapeWithControls = (props) => {
  const { index, update, theta, thetaStep, sound, vertexCount } = props;
  // update(key, data)
  const addVertex = (event) => {
    update(index, { vertexCount: vertexCount + 1 });
  };
  const removeVertex = (event) => {
    if (vertexCount > 2) {
      update(index, { vertexCount: vertexCount - 1 });
    }
  };
  const updateSound = (newSound) => {
    update(index, { sound: newSound });
  };
  return (
    <Card style={{ margin: "10px" }} className={"md:inline-block"}>
      <CardContent>
        <Stage
          width={225}
          height={225}
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Layer>
            <Polygon
              theta={theta}
              thetaStep={thetaStep}
              vertexCount={vertexCount}
              radius={100}
              xOffset={0}
              yOffset={0}
              sound={sound}
            />
          </Layer>
        </Stage>
        <Label
          htmlFor="beats"
          style={{ display: "block", width: "100%", textAlign: "center" }}
        >
          Beats
        </Label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5px",
          }}
        >
          <Button onClick={removeVertex}>-</Button>
          <b id="beats" style={{ fontSize: "24px" }}>
            {vertexCount}
          </b>
          <Button onClick={addVertex}>+</Button>
        </div>
        <Select onValueChange={updateSound} defaultValue={"bongo"}>
          <SelectTrigger>
            <SelectValue placeholder="Select a sound" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Sounds</SelectLabel>
              <SelectItem value="bongo">Bongo</SelectItem>
              <SelectItem value="cymbal">Cymbal</SelectItem>
              <SelectItem value="snare">Snare</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </CardContent>
    </Card>
  );
};
