import { Line } from "react-konva";
import { Indicator } from "./Indicator";

export const Polygon = (props) => {
  const { theta, thetaStep, vertexCount, radius, xOffset, yOffset, sound } =
    props;
  const figurePoints = calculateFigurePoints(vertexCount, radius);
  const lines = [];
  for (let i = 0; i < figurePoints.length; i++) {
    const previousPoint = wrapPrevious(i, figurePoints);
    const nextPoint = figurePoints[i];
    const coordinates = [
      previousPoint.x + xOffset,
      previousPoint.y + yOffset,
      nextPoint.x + xOffset,
      nextPoint.y + yOffset,
    ];
    lines.push(coordinates);
  }
  const lineCoordinates = lines;
  if (figurePoints.length === 0) {
    return <></>;
  }
  const pointAngleInterval = Math.ceil(360 / vertexCount);
  const pointIndex = Math.floor(theta / pointAngleInterval);
  if (pointIndex !== Math.floor((theta - thetaStep) / pointAngleInterval)) {
    sound();
  }
  const thisPoint = figurePoints[pointIndex];
  const prevPoint = wrapPrevious(pointIndex, figurePoints);
  const lineProgression = (theta % pointAngleInterval) / pointAngleInterval;
  const progressionPoint = coordinatesOnLine(
    prevPoint,
    thisPoint,
    lineProgression
  );
  const indicatorX = progressionPoint.x + xOffset;
  const indicatorY = progressionPoint.y + yOffset;
  return (
    <>
      {lineCoordinates.map((coordinates, i) => {
        return (
          <Line key={i} closed={true} stroke="black" points={coordinates} />
        );
      })}
      <Indicator x={indicatorX} y={indicatorY} />
    </>
  );
};

const coordinatesOnLine = (point1, point2, progressPercent) => {
  const slope = (point2.y - point1.y) / (point2.x - point1.x);
  const changeInX = (point2.x - point1.x) * progressPercent;
  const changeInY = slope * changeInX;
  return { x: point1.x + changeInX, y: point1.y + changeInY };
};

const calculateFigurePoints = (vertexCount, radius) => {
  const pointList = [];
  const pointAngleInterval = Math.ceil(360 / vertexCount);
  for (let theta = 0; theta < 360; theta++) {
    const thetaRadians = degreesToRadians(theta);
    const x = Math.cos(thetaRadians) * radius + radius;
    const y = Math.sin(thetaRadians) * radius + radius;
    if (theta % pointAngleInterval === 0) {
      pointList.push({ x: x, y: y });
    }
  }
  return pointList;
};

const degreesToRadians = (degrees) => {
  return degrees * (Math.PI / 180);
};

const wrapPrevious = (index, array) => {
  if (index === 0) {
    return array[array.length - 1];
  }
  return array[index - 1];
};
